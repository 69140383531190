import React, { useEffect, useState, useContext, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import PropTypes from 'prop-types';
import { Button, Loader, withModalContext, useModal } from '@jutro/components';
import { useTranslator, IntlContext } from '@jutro/locale';
import { isCapabilityEnabled, getNormalizedLOBName } from 'gw-portals-config-js';
import { useAuthentication } from 'gw-digital-auth-react';
import { PolicyService } from 'gw-capability-policy';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import { DocumentService } from 'gw-capability-policydocument';
import {
    PolicyCommonDetails,
    PolicyCommonDocuments,
    PolicyCommonContacts,
    PolicyCoverages,
    PolicyPremiumDetails
} from 'gw-capability-policy-common-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PolicyDetails.metadata.json5';
import messages from './PolicyDetails.messages';
import styles from './PolicyDetails.module.scss';

const handlePrint = () => {
    window.print();
};

function PolicyDetails(props) {
    const { history, match } = props;
    const { authHeader } = useAuthentication();
    const [policyCommonDetails, updatePolicyCommonDetails] = useState({
        currentPeriodDetails: {},
        renewedPeriodDetails: {},
        hasRenewedPeriod: false,
        billingDocuments: []
    });

    const [writableDocuments, updateWritableDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const intl = useContext(IntlContext);
    const translator = useTranslator();
    const { capabilitiesConfig } = appConfig;
    const policyNumber = _.get(match, 'params.policyNumber');
    const {
        showAlert
    } = useModal();

    const getPolicyDetails = useCallback(async () => {
        let policyDetails;
        try {
            policyDetails = await PolicyService.getAccountPolicyDetails(policyNumber, authHeader);
            if(policyDetails !== undefined){
                await AccountBillingDetailsService.getBillingDocuments(policyNumber,authHeader)
                .then((billingDocumentsResp) => {
                    policyDetails.currentPeriod.documentDTOs = [...policyDetails.currentPeriod.documentDTOs, ...billingDocumentsResp]
                })
            }
        } catch (error) {
            
        } finally{
            updatePolicyCommonDetails({
                currentPeriodDetails: policyDetails.currentPeriod,
                renewedPeriodDetails: policyDetails.renewedPeriod ? policyDetails.renewedPeriod : {},
                hasRenewedPeriod: !_.isEmpty(policyDetails.renewedPeriod)
            });
        }
        setLoading(false);
    }, [policyNumber, authHeader]);

    const createPolicyPayrollActivity = useCallback(async () => {
        const payrollActivityCreationRes = await PolicyService.createPolicyPayrollActivity(
            policyNumber, authHeader);
        showAlert({
            title: translator(payrollActivityCreationRes
                ? messages.successText : messages.failureText),
            message: translator(payrollActivityCreationRes
                ? messages.payrollActivitySuccessMessage : messages.payrollActivityFailureMessage),
            status: payrollActivityCreationRes ? 'success' : 'error',
            confirmButtonText: messages.okText
        }).catch(_.noop);
    }, [policyNumber, authHeader]);

    const redirectToEndorsement = useCallback(
        (policyDetails, requestType) => {
            const productCode = _.findKey(policyDetails.lobs, (lob) => !_.isEmpty(lob));
            history.push(
                `/endorsement-${getNormalizedLOBName(productCode)}`,
                { policyNumber, policyDetails, requestType }
            );
        },
        [history, policyNumber]
    );

    const canChangePolicy = useCallback(
        (policyPeriod) => {
            if (!_.isEmpty(policyPeriod)) {
                const productCode = _.findKey(policyPeriod.lobs, (lob) => !_.isEmpty(lob));
                if (_.isEmpty(productCode)) {
                    return false;
                }
                return (
                    isCapabilityEnabled({
                        capabilitiesConfig,
                        capabilityName: 'policychange',
                        lob: productCode
                    }) && policyPeriod.canBeChanged
                );
            }
            return false;
        },
        [capabilitiesConfig]
    );

    const getPolicyDownloadLink = useCallback(() => {
        return DocumentService.downloadPolicyDocument(
            _.get(policyCommonDetails.currentPeriodDetails, 'idCardPublicID'),
            _.get(policyCommonDetails.currentPeriodDetails, 'idCardSessionID')
        );
    }, [policyCommonDetails]);

    useEffect(
        () => {
            try {
                getPolicyDetails();
            } catch (e) {
                throw new Error(e);
            }
        },
        // Only need to run once when initial page render and when documents are added/removed
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [writableDocuments.length]
    );
    
    const setPendingCancellationWarningVisibility = (pendingCancellation) => {
        return !_.isNull(pendingCancellation)
            && !_.isUndefined(pendingCancellation) && pendingCancellation;
    };

    const setPayrollReportMessageVisibility = (payrollMonth) => {
        return !_.isNull(payrollMonth)
            && !_.isUndefined(payrollMonth) && !_.isEmpty(payrollMonth);
    };

    function PolicyPayrollReportMessage(componentProps) {
        return (
            <>
                <div className={componentProps.className}>
                    <p>
                        {translator(messages.payrollReportMessage, {
                            month: policyCommonDetails.currentPeriodDetails.payRollMonth,
                            dueDate: intl.formatDate(
                                new Date(policyCommonDetails.currentPeriodDetails.payRollDueDate),
                                { year: 'numeric', month: 'numeric', day: 'numeric' }
                            )
                        })}
                    </p>
                    <span>
                        <Button
                            id="Button"
                            size="medium"
                            type="filled"
                            onClick={createPolicyPayrollActivity}
                        >
                            {translator(messages.requestPayrollReport)}
                        </Button>
                    </span>
                </div>
            </>
        );
    }
    PolicyPayrollReportMessage.propTypes = {
        title: PropTypes.shape({})
    };

    const overrideProps = {
        pageTitle: {
            content: translator(messages.pageTitle, {
                policyNumber: policyCommonDetails.currentPeriodDetails.policyNumber
            })
        },
        currentPeriod: {
            heading: translator(messages.currentTab)
        },
        renewalPeriod: {
            heading: policyCommonDetails.hasRenewedPeriod ? translator(messages.renewalTab) : '',
            disabled: !policyCommonDetails.hasRenewedPeriod
        },
        policyChangeButtonContainer: {
            visible: canChangePolicy(
                policyCommonDetails.currentPeriodDetails,
                policyCommonDetails.currentPeriodDetails.productName
            )
        },
        renewalPolicyChangeButtonContainer: {
            visible: canChangePolicy(
                policyCommonDetails.renewedPeriodDetails,
                policyCommonDetails.currentPeriodDetails.productName
            )
        },
        idCardContainer: {
            visible: !_.isEmpty(_.get(policyCommonDetails.currentPeriodDetails, 'idCardPublicID'))
        },
        idCard: {
            href: getPolicyDownloadLink(),
            target: '_blank'
        },
        policyDocumentsSection: {
            updateWritableDocuments: updateWritableDocuments,
            writableDocuments: writableDocuments
        },
        pendingCancellationwarning: {
            visible: setPendingCancellationWarningVisibility(_.get(policyCommonDetails.currentPeriodDetails, 'pendingCancellation'))
        },
        policyCommonDetailsForPayrollReportingMessage: {
            visible: setPayrollReportMessageVisibility(
                policyCommonDetails.currentPeriodDetails.payRollMonth
            )
        }
    };
debugger;
    const resolvers = {
        resolveComponentMap: {
            policycommondetailscomponent: PolicyCommonDetails,
            policycoveragescomponent: PolicyCoverages,
            policycommondocumentscomponent: PolicyCommonDocuments,
            policycommoncontactscomponent: PolicyCommonContacts,
            policyPremiumDetailsComponent: PolicyPremiumDetails,
            policyPayrollReportMessageComponent: PolicyPayrollReportMessage
        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onPrint: handlePrint,
            onChangePolicy: () => redirectToEndorsement(policyCommonDetails.currentPeriodDetails, 'policyChange'),
            onChangePolicyRenewal: () => redirectToEndorsement(policyCommonDetails.renewedPeriodDetails, 'policyRenew')
        }
    };

    return (
        <Loader loaded={!loading}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyCommonDetails}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </Loader>
    );
}
PolicyDetails.propTypes = {
    match: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        productCode: PropTypes.string
    }).isRequired
};

export default withRouter(withModalContext(PolicyDetails));
