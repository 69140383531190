import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class AccountBillingDetailsService {
    /**
     * Retrieves an accounts billing summary.
     *
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof AccountBillingDetailsService
     */
    static getAccountBillingSummary(authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('billingData'), 'getAccountBillingSummary', [], authHeader);
    }

    /**
     * Retrieves an invoice summary for account.
     *
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     */
    static getAccountInvoiceStreamInformation(authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('billingData'), 'getAccountInvoiceStreamInformation', [], authHeader);
    }

    /**
     * Retrieves details of invoices for the invoice id.
     *
     * @param {String} invoiceStreamId a public-id of invoice stream data
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     */
    static getInvoiceStreamDetails(invoiceStreamId, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('billingData'), 'getInvoiceStreamDetails', [invoiceStreamId], authHeader);
    }

    /**
     * Set a payment instrument as automatic payment instrument.
     *
     * @param {String} invoiceStreamId a public-id of invoice stream data
     * @param {Object} instrument a payment instrument in PaymentInstrumentDTO
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     */
    static setPaymentGroupPaymentInformation(invoiceStreamId, instrument, authHeader = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('billingData'),
            'setPaymentGroupPaymentInformation',
            [invoiceStreamId, instrument],
            authHeader
        );
    }

    /**
     * Pay for selected invoices with a payment instrument.
     *
     * @param {Array} invoiceIds array of public-ids of invoices
     * @param {Object} amount a payment amount in BigDecimal
     * @param {Object} instrument a payment instrument in PaymentInstrumentDTO
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     */
    static makeDirectBillPayment(invoiceIds, amount, instrument, authHeader = {}) {
        const directBillPaymentDto = {
            invoiceIds,
            amountPaid: amount,
            paymentInstrument: instrument
        };

        return JsonRPCService.send(
            getProxiedServiceUrl('directBillPayment'),
            'makeDirectBillPayment',
            [directBillPaymentDto],
            authHeader
        );
    }

    /**
     * Retrieves accounts billing delivery method
     *
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     */
    static getInvoiceDeliveryMethods(authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('billingData'), 'getInvoiceDeliveryMethods', [], authHeader);
    }

    /**
     * Sets a new billing delivery method for the account
     *
     * @param {String} newDeliveryMethod a delivery method code
     * @param {String} accountNumber an account number to set the delivery method
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     */
    static setInvoiceDeliveryMethodForAccount(newDeliveryMethod, accountNumber, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('billingData'), 'setInvoiceDeliveryMethodForAccount', [newDeliveryMethod, accountNumber], authHeader);
    }

    /**
     * Set a payment instrument as automatic payment instrument.
     *
     * @param {String} policyNumber
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     */
    static createPaymentReportRecord(policyNumber, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('billingData'), 'createPaymentReportRecord', [policyNumber], authHeader);
    }

    static getBillingDocuments(policyNumber, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('billingData'), 'getBillingDocuments', [policyNumber], authHeader);
    }
}
